import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import cartBrown from "../../assets/img/cart-brown.svg";
import cart from "../../assets/img/cart.svg";
import { CartCard } from "./CartCard";
import userContext from "../../context/UserContext";
export const Header = ({ active }) => {
  const { user, setUser } = useContext(userContext);
  const [activenavbar, setactivenavbar] = useState(false);
  return (
    <div className="h-[100px] bg-[#2D251A]">
      <div
        className={`h-[100vh] w-full fixed transition-all duration-500 left-[100%] top-0 flex justify-end z-50   ${
          user.asideActive && "!left-0"
        }`}
      >
        <span
          onClick={(e) => {
            setUser({
              ...user,
              asideActive: false,
            });
          }}
          className={`h-[100vh] w-full transition-all duration-500 left-0 top-0 bg-[transparent] absolute ${
            user.asideActive && "!bg-[#000000a1]"
          }`}
        ></span>
        <aside className="h-[100vh] bg-[#fff] relative w-[330px]">
          <div className="border-b-[1px] border-b-[#ddd] h-[80px] flex items-center justify-center relative">
            <h1 className="text-[20px] font-light">Din kundvagn</h1>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000"
              onClick={(e) => {
                setUser({
                  ...user,
                  asideActive: false,
                });
              }}
              className="w-6 h-6 absolute top-1/2 -translate-y-1/2 right-4 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <ul className="overflow-auto h-[80vh] pb-32">
            {user.cartItems.map((EachItem, key) => (
              <CartCard EachItem={EachItem} key={key} />
            ))}
          </ul>
          <div className="absolute bottom-0 left-0 bg-[#fff] flex flex-col gap-1 w-full p-4">
            {/* <p className="font-bold text-center">Subtotal: Kr {total}</p> */}
            <button
              className="bg-[#000] text-[#fff] p-2 px-6 text-[14px] 
            rounded-[6px] font-semibold w-full"
              onClick={(e) => {
                setUser({
                  ...user,
                  asideActive: false,
                });
              }}
            >
              Fortsätt handla
            </button>

            <Link
              to="/cart"
              className="bg-[#000] text-[#fff] p-2 px-6 text-[14px] 
            rounded-[6px] font-semibold w-full flex items-center justify-center"
            >
              Se kundvagn
            </Link>

            <Link
              to="/checkout"
              className="bg-[#000] text-[#fff] p-2 px-6 text-[14px] 
            rounded-[6px] font-semibold w-full flex items-center justify-center"
            >
              till utcheckning
            </Link>
          </div>
        </aside>
      </div>
      <div className="w-[90%] max-w-[1300px] mx-auto h-full flex items-center justify-between ">
        <Link to="/">
          <img src={logo} alt="" className="w-[70px]" />
        </Link>

        <nav
          className={`flex items-center gap-10 lg:fixed lg:flex-col lg:top-0 lg:left-[-250px]  lg:w-[250px] transition-all duration-500 lg:bg-[#2D251A] lg:h-[100vh] lg:z-50 lg:pt-10 ${
            activenavbar && "lg:!left-0"
          }`}
        >
          <Link
            to="/"
            className={`text-[#fff] text-[18px] ${
              active === 1 &&
              "!text-[#AD9778] border-b-[1px] border-b-[#AD9778]"
            }`}
          >
            Hem
          </Link>
          <Link
            to="/about"
            className={`text-[#fff] text-[18px] ${
              active === 2 &&
              "!text-[#AD9778] border-b-[1px] border-b-[#AD9778]"
            }`}
          >
            Om oss
          </Link>
         
          <Link
            to="/online-store"
            className={`text-[#fff] text-[18px] ${
              active === 4 &&
              "!text-[#AD9778] border-b-[1px] border-b-[#AD9778]"
            }`}
          >
            Online butik
          </Link>
          
          <Link to="/cart" className="ml-5">
            <img src={cart} alt="" className="w-[25px]" />
          </Link>
        </nav>

        <svg
          onClick={(e) => {
            setactivenavbar(!activenavbar);
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#fff"
          className="w-6 h-6 hidden lg:block"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>

      <div
        className="fixed bottom-10 right-7 z-30  bg-[#fff] w-[60px] h-[60px] rounded-full flex items-center justify-center cursor-pointer transition-all duration-500 hover:scale-[115%]"
        onClick={(e) => {
          setUser({
            ...user,
            asideActive: true,
          });
        }}
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
      >
        <span className="bg-[#000] w-[30px] h-[30px] flex items-center justify-center text-[#fff] text-[14px] rounded-full absolute -top-[10px] -left-[10px]">
          {user.cartItems.length}
        </span>
        <img src={cartBrown} alt="" className="w-[25px]" />
      </div>
    </div>
  );
};
