import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "./index.css";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Support } from "./pages/Support";
import { Contact } from "./pages/Contact";
import { Store } from "./pages/Store";
import UserContextProvider from "./context/UserContextProvider";
import { BookDetails } from "./pages/BookDetails";
import { Cart } from "./pages/Cart";
import Checkout from "./pages/Checkout";

const router = createBrowserRouter([
  {
    path: "/",
    Component() {
      return (
        <UserContextProvider>
          <Outlet />
        </UserContextProvider>
      );
    },
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/online-store",
        element: <Store />,
      },

      {
        path: "/contact",
        element: <Contact />,
      },

      {
        path: "/cart",
        element: <Cart />,
      },

      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/book/:id",
        element: <BookDetails />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
