import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import userContext from "../context/UserContext";
import axios from "axios";
export default function Checkout() {
  const [sendingstatus, setsendingstatus] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [number, setnumber] = useState("");
  const [postalcode, setpostalcode] = useState("");
  const [email, setemail] = useState("");
  const { user, setUser } = useContext(userContext);

  const placeorder = async (e) => {
    e.preventDefault();
    setsendingstatus(true);
    let preparedata = {
      firstname: firstname,
      lastname: lastname,
      address: address,
      city: city,
      number: number,
      email: email,
      postalcode: postalcode,
      order: user,
    };

    let data = await axios.post(
      "https://kifo-backend.vercel.app/api/contact",
      preparedata
    );
    setsendingstatus(false);
  };

  return (
    <div>
      <div className="mx-auto grid grid-cols-2 lg:block  w-[100%] ">
        <div className="w-[100%] h-[100vh] p-5 lg:px-3 px-10 overflow-auto border-0">
          <div>
            <h1 className="text-[30px] sm:text-[22px] leading-18">
              Vi behöver lite personlig information för att kunna leverera ordern till dig.
            </h1>
          </div>
          <div>
            <div className="border-[1px] border-[#ddd] p-3 rounded-lg mt-4">
              <h1 className="font-bold">NOTERA!</h1>
              <p>
                För att undvika besvär, vänligen fyll i informationen korrekt så att du kan få 
                ordern ordentligt.         
              </p>
            </div>

            <p className="text-[18px]  mb-3"></p>

            <div className="mt-7 flex flex-col gap-7">
              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="email" className="text-[17px]">
                  Email*
                </label>
                <div>
                  <input
                    type="email"
                    id="email"
                    className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                    placeholder="Din Email*"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  <small>Vi behöver Email för verifikation</small>
                </div>
              </div>
              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="fname" className="text-[17px]">
                  Förnamn*
                </label>
                <input
                  type="text"
                  id="fname"
                  className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                  placeholder="Ditt förnamn"
                  value={firstname}
                  onChange={(e) => {
                    setfirstname(e.target.value);
                  }}
                />
              </div>
              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="lname" className="text-[17px]">
                  Efternamn*
                </label>
                <input
                  type="text"
                  id="lname"
                  className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                  placeholder="Ditt efternamn"
                  value={lastname}
                  onChange={(e) => {
                    setlastname(e.target.value);
                  }}
                />
              </div>
              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="Address" className="text-[17px]">
                  Adress*
                </label>
                <input
                  type="text"
                  id="Address"
                  className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                  placeholder="Ange din adress"
                  value={address}
                  onChange={(e) => {
                    setaddress(e.target.value);
                  }}
                />
              </div>
              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="City" className="text-[17px]">
                  Stad*
                </label>
                <input
                  type="text"
                  id="City"
                  className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                  placeholder="Ange stad"
                  value={city}
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                />
              </div>

              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="phone" className="text-[17px]">
                  Telefon nummer*
                </label>
                <div>
                  <input
                    type="text"
                    id="phone"
                    className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                    placeholder="Telefonnummer"
                    value={number}
                    onChange={(e) => {
                      setnumber(e.target.value);
                    }}
                  />
                  
                </div>
              </div>

              <div className=" w-full flex flex-col  gap-3">
                <label htmlFor="pc" className="text-[17px]">
                  Postnummer
                </label>
                <input
                  type="text"
                  id="pc"
                  className="bg-[#F3F3F3] w-full h-[60px] text-[16px] outline-none px-4"
                  placeholder="Postnummer"
                  value={postalcode}
                  onChange={(e) => {
                    setpostalcode(e.target.value);
                  }}
                />
              </div>

              <div >
    {/* Updated total price display */}
    <h2 className="font-bold text-[24px] text-[#2D251A] text-right">
      Totalt: {user.price} Kr
    </h2>
  </div>
            </div>
           
          </div>
          <div>
            <button
              className="bg-[#2D251A] hover:bg-[#4d402e]  text-[#fff] font-bold w-full h-[60px] text-[17px]"
              onClick={placeorder}
              disabled={sendingstatus}
            >
              {sendingstatus ? "Vänligen vänta" : "Lägg order"}
            </button>
          </div>
        </div>
        <div
          className="h-[100vh] lg:hidden relative bg-[#EFEFEF]  bg-no-repeat  bg-center w-full"
          style={{
            backgroundImage: `url(https://cdn.pixabay.com/photo/2024/02/24/20/54/books-8594725_1280.jpg)`,
          }}
        >
          <span className="w-full h-full bg-[#2d251a8b] absolute left-0 top-0"></span>
        </div>
      </div>
    </div>
  );
}
