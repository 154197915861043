import React from "react";
import { Header } from "../components/common/Header";
import  Footer  from "../components/common/Footer";

export const Home = () => {
  return (
    <div>
      <Header active={1} />

      <main
        className="h-[700px] flex items-center justify-center relative"
        style={{
          background: `url(https://cdn.pixabay.com/photo/2014/09/05/18/32/old-books-436498_1280.jpg)`,
        }}
      >
        <span className="w-full h-full bg-[#2d251a8b] absolute left-0 top-0"></span>
        <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-center items-center relative z-10">
          <h1 className="text-[#fff] font-bold text-[50px] sm:text-[35px]">
            Välkommen till Kifo bokförlag
          </h1>
          <p className="text-[#fff] text-[25px] w-[600px] md:w-[90%] font-light text-center mt-2">
          </p>
        </div>
      </main>
      <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10 py-20">
        <p className="text-[20px] leading-[40px]">
        
        Bokförlaget som tillgängliggör den tidiga kyrkans rikedomar på svenska för både barn och vuxna.
        </p>
      </div>
      <Footer />

    </div>

  );
};
