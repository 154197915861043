import axios from "axios";
import { BASE_URL } from "./base";

export const getBooks = async () => {
  try {
    const data = await axios.get(`${BASE_URL}/api/get/books`);

    return data;
  } catch (err) {
    return err.response;
  }
};
