import React from "react";
import { Header } from "../components/common/Header";
import Footer from "../components/common/Footer";
import backgroundImage from "../assets/img/Om.png";

export const About = () => {
  return (
    <div>
      <Header active={2} />

      <main
        className="h-[1000px] bg-cover flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center right', // Align the image to the right
          backgroundSize: 'cover', // Ensure the image covers the entire background
          backgroundRepeat: 'no-repeat', // Ensure the image does not repeat
        }}
      >
        <span className="w-full h-full bg-[#2d251a8b] absolute left-0 top-0"></span>
        <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10">
          <h1 className="text-[#fff] font-bold text-[50px]">Om oss</h1>
          <p className="text-[#fff] text-[25px] w-[600px] md:w-[90%] font-light mt-2">
            {/* Text content here */}
          </p>
        </div>
      </main>

      <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10 py-20">
        <p className="text-[20px] leading-[40px]">
          Kifo Bokförlag grundades i oktober 2023.
          <br />
          Kifo Bokförlag har som mål att tillgängliggöra kristna barnböcker och översätta syriska kyrkofäders verk till svenska. Syftet med dessa böcker är att utbilda barn och vuxna i den kristna tron i allmänhet och i den Syrisk-ortodoxa kyrkans arv i synnerhet.
          <br />
          Grunden till kyrkans tro är bekännelsen som Simon gjorde om Jesus: "Du är Messias, den levande Gudens Son." (Matt 16:16) Då svarade Jesus: ‭"Salig är du, Simon, Jonas son, för det är inte kött och blod som har uppenbarat det för dig, utan min Far i himlen. Och jag säger dig: Du är Petrus, och på denna klippa ska jag bygga min församling, och helvetets portar ska inte få makt över den." (Matt 16:17-18)
          <br />
          Av denna anledning valde vi att namnge vårt bokförlag för Kifo eftersom det är det syriska ordet för Petrus som på grekiska betyder klippa. Vi hoppas att Kifo Bokförlag kan vara en trons klippa för många som tar del av de böcker som publiceras av Kifo Bokförlag.
          <br />
          Isabell Üre Kaya & Sebastian Kaya
        </p>
      </div>

      <Footer />
    </div>
  );
};
