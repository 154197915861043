import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import userContext from "../../context/UserContext";
export const StoreCard = ({ book }) => {
  const { user, setUser } = useContext(userContext);

  const addToCart = (e) => {
    let available = false;

    user.cartItems.forEach((eachcart) => {
      if (eachcart._id === book._id) {
        available = true;
        return false;
      } else {
        return true;
      }
    });

    if (available == false) {
      setUser({
        ...user,
        asideActive: true,
        cartItems: [...user.cartItems, { ...book, quantity: 1 }],
      });
    } else {
      setUser({
        ...user,
        asideActive: true,
      });
    }
  };

  useEffect(() => {
    let price = 0;
    user.cartItems.forEach((Eachitem) => {
    price = price + Eachitem.discountedPrice * Eachitem.quantity;
    });
    
    setUser({
    ...user,
    price: price,
    });
    }, [user]);

  return (
    <div
      style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.2)" }}
      className="flex flex-col"
    >
      <Link to={`/book/${book._id}`}>
        <img
          src={book.image}
          alt=""
          className="h-[400px] sm:h-[600px] w-full object-cover"
        />
      </Link>
      <div className="p-4 flex flex-col items-start flex-1">
        <h1 className="mb-2 text-[16px] w-[95%] font-bold flex-1">
          {book.bookname}
        </h1>
        <p className="mb-2 text-[16px] ">
          {book.bookprice != book.discountedPrice && (
            <strike className="text-[#7A797B] ">{book.bookprice} Kr</strike>
          )}
           {book.discountedPrice} Kr
        </p>

        <button
          className="bg-[#000] text-[#fff] p-3 px-6 text-[14px] rounded-full font-semibold "
          onClick={addToCart}
        >
          Lägg till i kundvagn
        </button>
      </div>
    </div>
  );
};
