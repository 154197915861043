import React, { useContext, useEffect, useState } from "react";
import { Header } from "../components/common/Header";
import { Link } from "react-router-dom";
import userContext from "../context/UserContext";
import { ShoppingCart } from "../components/cart/ShoppingCart";

export const Cart = () => {
  const { user, setUser } = useContext(userContext);
  const shippingPrice = 65; // Frakt (shipping price))

  useEffect(() => {
    let price = 0;

    // Calculate total book price
    user.cartItems.forEach((Eachitem) => {
      price = price + Eachitem.discountedPrice * Eachitem.quantity;
    });

    // Add shipping price only if at least one book is in the cart
    if (user.cartItems.length > 0) {
      price += shippingPrice;
    }

    setUser({
      ...user,
      price: price,
    });
  }, [user]);

  return (
    <div>
      <Header />

      <div className="mb-32 grid grid-cols-[1fr_350px] max-w-[1300px] gap-10 w-[90%] mx-auto mt-10 lg:grid-cols-1">
        <div>
          <h1 className="text-[35px] font-bold uppercase mb-10">
            Kundvagn
          </h1>

          <div className="mt-4 flex flex-col gap-5">
            {user.cartItems.map((EachItem) => (
              <ShoppingCart book={EachItem} />
            ))}
          </div>
        </div>

        <div className="mt-20">
          <h1 className="text-[20px] font-bold">Order sammanfattning</h1>
          <ul className="mt-7 border-b-[1px] border-b-[#d2d2d2] pb-5 flex flex-col gap-3">
            {user.cartItems.map((EachItem) => (
              <li className="flex items-center justify-between">
                <p className="text-[15px] w-[60%]">{EachItem.bookname}</p>
                <p className="font-bold text-[15px]">
                  {EachItem.discountedPrice} Kr x {EachItem.quantity}
                </p>
              </li>
            ))}

            {user.cartItems.length > 0 && (
              <li className="flex items-center justify-between mt-1 border-b-[1px] border-b-[#d2d2d2] pb-5">
                <p className="text-[15px]">
                  Frakt <br />
                </p>
                <p className="font-bold text-[15px]">65 Kr</p>
              </li>
            )}

            <li className="flex items-center justify-between mt-2 mb-2">
              <p className="text-[18px] font-bold">Total</p>
              <p className="font-bold text-[18px]">{user.price} Kr</p>
            </li>
            <li>
              <Link
                variant="outline"
                to="/checkout"
                className={`font-bold flex items-center justify-center  uppercase rounded-[4px] border-[1px] bg-[#2D251A] text-[#fff] px-3 py-4 w-full text-[14px] mt-1 `}
              >
                Fortsätt till utcheckning
              </Link>
              <Link
                to="/online-store"
                variant="outline"
                className={`font-bold  uppercase rounded-[4px] border-[1px] border-[#2D251A] text-[#2D251A] px-3 py-3 w-full text-[14px] mt-3 flex items-center justify-center`}
              >
                FORTSÄTT HANDLA
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
