import React, { useContext } from "react";
import userContext from "../../context/UserContext";

export const CartCard = ({ EachItem }) => {
  const { user, setUser } = useContext(userContext);
  const deleteCartCard = (e) => {
    let newcartitem = user.cartItems.filter((eachcart) => {
      if (eachcart._id === EachItem._id) {
        return false;
      } else {
        return true;
      }
    });
    setUser({
      ...user,
      cartItems: newcartitem,
    });
  };
  return (
    <li className="flex items-center gap-3 px-4 py-4 border-b-[1px] border-b-[#ddd] ">
      <img
        src={EachItem.image}
        alt=""
        className="min-w-[87px] w-[87px] h-[116px] object-cover"
      />
      <div className="flex-1">
        <h1 className="font-bold text-[14px]">{EachItem.bookname}</h1>
        <p className="text-[14px] mt-1">{EachItem.discountedPrice} Kr</p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="#000"
        className="w-5 min-w-5 cursor-pointer"
        onClick={(e) => {
          deleteCartCard();
        }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
        />
      </svg>
    </li>
  );
};
