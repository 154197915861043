import axios from "axios";
import { BASE_URL } from "./base";

export const getBookById = async (id) => {
  try {
    const data = await axios.get(`${BASE_URL}/api/get/book/id?id=${id}`);
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
