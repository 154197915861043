import React from "react";
import { Header } from "../components/common/Header";

export const Support = () => {
  return (
    <div>
      <Header active={3} />

      <main
        className="h-[600px] bg-cover flex items-center justify-center relative"
        style={{
          backgroundImage: `url(https://cdn.pixabay.com/photo/2015/07/31/11/45/library-869061_1280.jpg)`,
        }}
      >
        <span className="w-full h-full bg-[#2d251a8b] absolute left-0 top-0"></span>
        <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10">
          <h1 className="text-[#fff] font-bold text-[50px]">Support</h1>
          <p className="text-[#fff] text-[25px] w-[600px] md:w-[90%] font-light  mt-2">
            
          </p>
        </div>
      </main>
      <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10 py-20">
        <p className="text-[20px] leading-[40px]">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Porro, aut
          corrupti esse sint obcaecati nihil totam in? Nulla aliquid dolore
          dignissimos dolorum, quos officiis provident maxime eius enim illum
          odit. Voluptate iure obcaecati numquam, quo nam atque consequatur
          saepe est molestias, perspiciatis quasi. Delectus dolorum
          necessitatibus impedit libero commodi porro laborum ullam incidunt, ab
          aliquam in, corporis itaque repellat quae voluptatem quo a nihil sequi
          deleniti, quam numquam nulla. Cum alias esse voluptatum quas
          exercitationem, beatae deserunt consequuntur voluptas repellendus quae
          accusantium odio, error labore vel minus? Debitis fugit similique
          animi, veritatis minima deleniti magnam suscipit doloremque tempore!
          Tempore, nobis!
        </p>
      </div>
    </div>
  );
};
