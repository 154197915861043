import React, { useContext, useState } from "react";
import userContext from "../../context/UserContext";
export const ShoppingCart = ({ book }) => {
  const [quantitiy, setquantitiy] = useState(1);
  const { user, setUser } = useContext(userContext);

  const deleteCartCard = (e) => {
    let newcartitem = user.cartItems.filter((eachcart) => {
      if (eachcart._id === book._id) {
        return false;
      } else {
        return true;
      }
    });
    setUser({
      ...user,
      cartItems: newcartitem,
    });
  };

  const increaseQuantity = (e) => {
    let newcartitem = user.cartItems.filter((eachcart) => {
      if (eachcart._id === book._id) {
        eachcart.quantity++;
      }
      return true;
    });
    setUser({
      ...user,
      cartItems: newcartitem,
    });
  };

  const decreaseQuantity = (e) => {
    let newcartitem = user.cartItems.filter((eachcart) => {
      if (eachcart._id === book._id) {
        eachcart.quantity--;
      }
      return true;
    });
    setUser({
      ...user,
      cartItems: newcartitem,
    });
  };

  return (
    <div className="w-full">
      <div>
        <div className="flex items-center md:items-start  gap-3">
          <div>
            <img
              src={book.image}
              className="w-[130px] h-[200px] object-cover"
              alt=""
            />
          </div>
          <div className="flex flex-col flex-1  ">
            <h1 className="text-[15px] mb-1">{book.bookname}</h1>
            <p className="text-[12px] mb-3">{book.bookauthor}</p>
            <p className="text-[12px] font-bold text-[#000]">
              Pris: {book.discountedPrice} Kr
            </p>
            <p className="text-[12px] font-bold text-[#000]">
              Kvantitet: {book.quantity}
            </p>

            <div className="grid mt-5 grid-cols-[130px_1fr] gap-5 md:grid-cols-1 md:gap-2">
              <div className="flex items-center justify-between">
                <button
                  onClick={(e) => {
                    if (book.quantity > 1) {
                      setquantitiy(book.quantity - 1);
                      decreaseQuantity();
                    }
                  }}
                  className=" rounded-md w-[39px] h-[39px] border-[1px] border-[#2D251A] flex items-center justify-center hover:bg-[#F2F4FB]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#2D251A"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 12h14"
                    />
                  </svg>
                </button>
                <h1>{book.quantity}</h1>
                <button
                  onClick={(e) => {
                    setquantitiy(book.quantity + 1);
                    increaseQuantity();
                  }}
                  className=" rounded-md w-[39px] h-[39px] border-[1px] border-[#2D251A] flex items-center justify-center hover:bg-[#F2F4FB]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#2D251A"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
              </div>
              <button
                onClick={deleteCartCard}
                variant="outline"
                className={`font-bold  uppercase rounded-[4px] border-[1px] bg-[#2D251A] text-[#fff] px-3 py-3 w-full text-[12px] mt-1 `}
              >
                Ta bort
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
