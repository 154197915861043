import React, { useEffect, useState } from "react";
import { Header } from "../components/common/Header";
import { StoreCard } from "../components/common/StoreCard";
import { getBooks } from "../api/getBooks";
import  Footer  from "../components/common/Footer";
import backgroundImage from "..//assets/img/butik.png"


export const Store = () => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    const getallbooks = async () => {
      let { data } = await getBooks();

      setBooks(data);
    };
    getallbooks();
  }, []);
  return (
    <div>
      <Header active={4} />

      <main
        className="h-[1000px] bg-cover flex items-center justify-center relative"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center right', // Align the image to the right
          backgroundSize: 'cover', // Ensure the image covers the entire background
          backgroundRepeat: 'no-repeat', // Ensure the image does not repeat
        }}
      >
        <span className="w-full h-full bg-[#2d251a8b] absolute left-0 top-0"></span>
        <div className="w-[90%] max-w-[1300px] mx-auto flex flex-col justify-start items-start relative z-10">
          <h1 className="text-[#fff] font-bold text-[50px]">Böcker</h1>
          <p className="text-[#fff] text-[25px] w-[600px] md:w-[90%] font-light  mt-2">
            Här kan du handla bland våra böcker.
          </p>
        </div>
      </main>
      <div className="w-[90%] max-w-[1100px] lg:grid-cols-2 sm:!grid-cols-1 mx-auto z-10 py-20 grid grid-cols-4 gap-3">
        {books.map((EachBook, key) => (
          <StoreCard book={EachBook} key={key} />
        ))}
      </div>
      <Footer />

    </div>
  );
};
