import React, { useContext, useEffect, useState } from "react";
import { Header } from "../components/common/Header";
import { useParams } from "react-router-dom";
import userContext from "../context/UserContext";
import { StoreCard } from "../components/common/StoreCard";
import book1 from "../assets/img/book1.jpeg";
import book2 from "../assets/img/book2.jpeg";
import { getBookById } from "../api/getBookById";
export const BookDetails = () => {
  const params = useParams();
  const { user, setUser } = useContext(userContext);
  const [book, setBook] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);

    const getIdBook = async () => {
      let { data } = await getBookById(params.id);

      setBook(data);
    };
    getIdBook();
  }, []);

  const addToCart = (e) => {
    let available = false;

    user.cartItems.forEach((eachcart) => {
      if (eachcart._id === params.id) {
        available = true;
        return false;
      } else {
        return true;
      }
    });
    if (available == false) {
      setUser({
        ...user,
        asideActive: true,
        cartItems: [...user.cartItems, { ...book, quantity: 1 }],
      });
    } else {
      setUser({
        ...user,
        asideActive: true,
      });
    }
  };
  return (
    <div>
      <Header active={4} />

      <div className="w-[90%] max-w-[1100px] mx-auto z-10 py-20 lg:block grid grid-cols-2 gap-10 lg">
        <img src={book.image} alt="" className="w-full lg:mb-10" />
        <div>
          <h1 className="text-[32px] font-bold">{book.bookname}</h1>
          <ul className="my-10 flex flex-col gap-3 pl-5 ">
            <li className="text-[18px] font-normal">
              <b>Författare:</b> {book.bookauthor}
            </li>
            {book.booktranslator && (
              <li className="text-[18px] font-normal">
                <b>Översättare:</b> {book.booktranslator}
              </li>
            )}
            {book.bookpublisher && (
              <li className="text-[18px] font-normal">
                <b>Utgivare:</b> {book.bookpublisher}
              </li>
            )}
            <li className="text-[18px] font-normal">
              <b>Antal sidor:</b> {book.bookpages}
            </li>
            {book.booklanguage && (
              <li className="text-[18px] font-normal">
                <b>Språk:</b> {book.booklanguage}
              </li>
            )}
          </ul>

          {book.bookprice != book.discountedPrice && (
            <p className="text-[30px] font-semibold line-through text-[#666]">
               {book.bookprice} Kr
            </p>
          )}

          <p className="text-[30px] font-semibold text-[#000]">
             {book.discountedPrice} Kr
          </p>

          <button
            className="bg-[#000] mt-5 text-[#fff] p-4 px-10 text-[16px] rounded-full font-semibold "
            onClick={addToCart}
          >
            Lägg till i kundvagn
          </button>
        </div>

        <div className="col-span-2 mt-5">
          <h1 className="text-[32px] font-bold">Beskrivning</h1>
          <p className="mt-2 leading-[35px] text-[18px]">
            {book.description}
          </p>
        </div>

        {/* <div className="col-span-2 mt-5 grid grid-cols-4 gap-4">
          <h1 className="text-[32px] font-bold col-span-4 mb-5">More Books</h1>
        </div> */}
      </div>
    </div>
  );
};
