import React from 'react';
import '../../../src/Footer.css';
import { SocialIcon } from 'react-social-icons'


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <p>+46709908544</p>
        <p>info@kifobokforlag.se</p>
      </div>
      <div className="footer-center">
      <SocialIcon href="https://www.facebook.com/profile.php?id=61552417529197&locale=sv_SE" url="www.facebook.com" />
      <SocialIcon href="https://www.instagram.com/kifobokforlag/" url="www.instagram.com" />

      </div>
      <div className="footer-right">
        <p>Karlhovsvägen 28,</p>
        <p>151 37 Södertälje</p>
        <p>Bankgiro: 302-8883</p>
        <p>Swish: 1232431526</p>
      </div>
    </footer>
  );
};

export default Footer;
